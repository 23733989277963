import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Dotfiles extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        {/* <Helmet title={title} /> */}
        <SEO
            title="Dotfiles"
            description="Use files to help you with your personal configuration"
            keywords={[`data`, `dotfiles`,`vim`, `bash`, `tmux`]}
          />
        <div className="blog-wrapper">
          {/* <h2>Dotfiles</h2> */}
          {/* <p>
          I use vscode for my development, vim for the quick editing while running urxvt inside tmux on bash shell.
          </p>
          <br></br> */}
          <p>
          If you are interested, peek in my dotfiles. You can get them <a href="https://github.com/nmihaica/dotfiles">here</a>.
          </p>
        </div>
      </Layout>
    )
  }
}

export default Dotfiles

